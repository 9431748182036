<div class="selected-ving" *ngIf="packet">
    <!--Components-->
    <div class="analytics-panel-1-container">
        <!--Title-->
        <div class="header">
            <!--Owner-->
            <ng-container *ngIf="packet.share.owner">
            <div class="owner">
                <div
                    class="icon"
                    [style.background]="packet.share.owner.hexColor"
                >
                    <span [title]="packet.share.owner.name">{{
                        get2LetterName(packet.share.owner.name)
                    }}</span>
                </div>
            </div>
            </ng-container>
            <!--Title-->
            <div
                [class]="packet.title ? 'title' : 'title title-not-set'"
                title="{{ packet.title }}"
            >
                {{ packet.title ? packet.title : 'No title set' }}
            </div>
            <!--Premium-->
            <div class="star" *ngIf="packet.premium">
                <i class="material-icons icon" title="Premium">stars</i>
            </div>
        </div>

        <!--Summary-->
        <div class="summary" *ngIf="packet.summary">
            <p>{{ packet.summary }}</p>
        </div>

        <!--Duration-->
        <div class="duration" *ngIf="packet.duration">
            <div class="material-icons icon">timer</div>
            <div class="duration-text">Requires about {{ this.displayDuration() }} minutes</div>
        </div>


        <!--Overview-->
        <div class="overview">
            <!--Preview button-->
            <button
                class="app-button dark small"
                (click)="displayComponentOverview()"
            >
                {{ shareType }} Overview
            </button>
        </div>

        <!--List-->
        <div class="list">
            <ng-container *ngFor="let component of packet['components']">
                <div
                    [class]="getSelectedComponentClass(component.id)"
                    (click)="handleClickComponent(component)"
                >
                    <div
                        [class]="
                            component.title ? 'title' : 'title no-title-set'
                        "
                        [title]="component.title"
                    >
                        <!--Type-->
                        <div class="type-icon">
                            <i class="material-icons icon">{{
                                icons[component.type]
                            }}</i>
                        </div>
                        <!--Premium-->
                        <div class="star-icon" *ngIf="component.premium">
                            <i class="material-icons icon" title="Premium"
                                >stars</i
                            >
                        </div>
                        {{ component.title ? component.title : 'No title set' }}
                    </div>
                </div>
                <div
                    class="question-list"
                    *ngIf="component.questions && component.questions.length"
                >
                    <div
                        *ngFor="let question of component.questions"
                        [class]="getSelectedQuestionClass(question.id)"
                        (click)="handleClickQuestion(question)"
                    >
                        <div
                            [class]="
                                question.text ? 'text' : 'text no-text-set'
                            "
                            [title]="question.text"
                        >
                            {{ question.text ? question.text : 'No text set' }}
                        </div>
                        <!--Type-->
                        <span class="type">{{
                            question.friendlyType
                        }}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <!--Actions-->
    <div class="actions">
        <!--Share button-->
        <div class="hover-menu">
            <button class="app-button small blue" [disabled]="!canShareAndPreview(packet)">
                <i class="material-icons icon">share</i> Share
                <i class="material-icons icon arrow">arrow_drop_down</i>
            </button>
            <div class="hmenu" *ngIf="canShareAndPreview(packet)">
                <a
                    href="javascript:"
                    class="hmenu-link"
                    (click)="displayInviteModal()"
                >
                    <i class="material-icons icon">person_add</i> Assign/Invite
                </a>
                <a
                    href="javascript:"
                    class="hmenu-link"
                    (click)="displayGetLinkModal()"
                >
                    <i class="material-icons icon">insert_link</i> Link
                </a>
                <a
                    href="javascript:"
                    class="hmenu-link"
                    (click)="displayQRCodeModal()"
                    *ngIf="packet.share.qrCode?.imageUrl"
                >
                    <img
                        src="/assets/images/icons/qr-code.png"
                        alt="QR"
                        width="18"
                    />
                    QR Code
                </a>
            </div>
        </div>
        <!--Recreate button-->
        <div class="hover-menu">
            <button class="app-button small large blue">
                <i class="material-icons icon">cached</i> Recreate
                <i class="material-icons icon arrow">arrow_drop_down</i>
            </button>
            <div class="hmenu">
                <button class="hmenu-link" [ngClass]="{'hide': !userCanCopy}" (click)="recreatePacket('COPY')">
                    <i class="material-icons icon">file_copy</i> Copy
                </button>
                <button class="hmenu-link" [ngClass]="{'hide': !userCanRevise}" (click)="recreatePacket('REVISE')">
                    <i class="material-icons icon">edit</i> Revise
                </button>
            </div>
        </div>
        <!--Preview button-->
        <div class="hover-menu">
            <button
                class="app-button small blue"
                (click)="displayPacketPreview()"
                [disabled]="!canShareAndPreview(packet)"
            >
                Preview
            </button>
        </div>
        <!--Revision button-->
        <div class="hover-menu history" *ngIf="isRevisionsExists()">
            <a href="javascript:" class="icon-button">
                <i class="material-icons icon">history</i>
            </a>
            <div class="hmenu revisions large">
                <div class="hmenu-title">
                    Revisions
                    <span class="badge">{{
                        packet.share.revisions.length
                    }}</span>
                </div>
                <div class="hmenu-content">
                    <ng-container
                        *ngFor="let revision of packet.share.revisions"
                    >
                        <div
                            class="hmenu-item"
                            (click)="
                                handleRevisionPacket(revision.packetId)
                            "
                        >
                            <small *ngIf="revision.published">{{
                                displayDate(revision.published)
                            }}</small>
                            <p class="ellipsis">{{ revision.title }}</p>
                            <span class="close" *ngIf="!revision.active">
                                <i class="material-icons icon">close</i>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!--Body-->
    <div class="body">
        <!--Processing Notice-->
        <div class="notice" *ngIf="orgHasAccess && !canShareAndPreview(packet)">
            Some actions and settings are temporarily disabled while we finish processing and optimizing your files. This can take few minutes to complete but you don't need to wait here...
            <input type="button" class="text-button" value="Refresh" onClick="window.location.reload()">
        </div>
        <!--No Access Notice-->
        <div class="notice" *ngIf="!orgHasAccess">
            Your organization has limited access to this feature. You can preview the material, but you'll need to upgrade to share and collect analytics. To learn more, contact:  <a href="mailto:sales@vingapp.com">sales@vingapp.com</a>
        </div>
        <!--Overview-->
        <div
            class="selected-view {{
                selectedComponentId ? 'component' : 'overview'
            }}"
            *ngIf="isOverviewSelected || selectedComponentId"
        >
            <div class="selected-component-header" *ngIf="selectedComponentId">
                <!--Title-->
                <div
                    class="title"
                    *ngIf="!selectedQuestionId && selectedQuestion"
                >
                    {{ selectedComponentTitle }}
                </div>
            </div>

            <!--Chart-->
            <div id="selected-view-chart" *ngIf="analytics">
                <!--If chart has data to display-->
                <div
                    id="standard-doughnut-chart"
                    class="selected-view-chart-inner margin-right"
                ></div>

                <!--If chart has no data to display-->
                <div class="no-chart-data" *ngIf="displayChart === false"></div>

                <div class="chart-center-info">
                    <div class="center-info-label">
                        {{ analytics.stats.total }}
                    </div>
                    <div class="center-info-value">
                        {{ entityName | pluralize: analytics.stats.total }}
                    </div>
                </div>
                <div class="chart-key">
                    <div
                        *ngFor="let item of chartKeyItems"
                        [class]="'chart-key-item ' + item.name"
                    >
                        <div class="item-color">
                            <div class="color-box" style="background-color: {{ item.color }};"></div>
                        </div>
                        <div class="item-label">
                            {{ item.label }}
                        </div>
                        <div class="item-value">
                            {{ analytics.stats[item.name] }}
                        </div>
                    </div>
                </div>

                <!--Share Settings-->
                <div
                    class="share-settings"
                    *ngIf="isOverviewSelected && !selectedQuestionId"
                >
                    <div class="active-setting">
                        <div class="item">
                            <label>Active</label>
                            <app-toggle
                                size="small"
                                [(value)]="share.active"
                                [disabled]="!orgHasAccess || !userCanUpdate"
                            ></app-toggle>
                        </div>
                        <div class="item">
                            <label>Notification</label>
                            <app-toggle
                                size="small"
                                [(value)]="share.notify"
                                [disabled]="!share.active || !userCanUpdate"
                            ></app-toggle>
                        </div>
                        <div class="item">
                            <label>Required</label>
                            <app-toggle
                                size="small"
                                [(value)]="share.required"
                                (valueChange)=this.toggleRequired()
                                [disabled]="!share.active || !userCanUpdate"
                            ></app-toggle>
                        </div>
                        <div class="item microburst" *ngIf="share.required && !share.enforceOrder" [ngClass]="{'disabled': !packet.duration || !share.active || !userCanUpdate}">
                            <div class="microburst-settings" [ngClass]="{'disabled': !packet.duration || !share.active || !userCanUpdate}">
                                Due in
                                <input
                                    type="number"
                                    name="dueDays"
                                    value="{{ this.getDefaultDueDays() }}"
                                    min="1"
                                    oninput="validity.valid || (value = ''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                                    [(ngModel)]="share.dueDays"
                                /> days
                            </div>
                        </div>
                        <div class="item" *ngIf="share.type === 'packet'">
                            <label>Enforce Order</label>
                            <app-toggle
                                size="small"
                                [(value)]="share.enforceOrder"
                                (valueChange)=this.toggleEnforceOrder()
                                [disabled]="!share.active || !userCanUpdate"
                            ></app-toggle>
                        </div>
                        <div class="item microburst" *ngIf="share.required && share.enforceOrder && !this.sendReminders">
                            <div class="microburst-settings" [ngClass]="{'disabled': !packet.duration || !share.active || !userCanUpdate}">
                                Due in
                                <input
                                    type="number"
                                    name="dueDays"
                                    value="{{ this.getDefaultDueDays() }}"
                                    min="1"
                                    oninput="validity.valid || (value = ''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                                    [(ngModel)]="share.dueDays"
                                /> days
                            </div>
                        </div>
                        <div class="item microburst" *ngIf="share.required">
                            <div class="microburst-settings" [ngClass]="{'disabled': !packet.duration || !share.active || !userCanUpdate}">
                                Expires in
                                <input
                                    type="number"
                                    name="expirationDays"
                                    value="{{ this.getDefaultExpirationDays() }}"
                                    min="1"
                                    oninput="validity.valid || (value = ''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                                    [(ngModel)]="share.expirationDays"
                                /> days
                            </div>
                        </div>
                        <div class="item" *ngIf="share.type === 'packet'">
                            <label>Reminders</label>
                            <app-toggle
                                size="small"
                                [(value)]="this.sendReminders"
                                (valueChange)=this.toggleReminders()
                                [disabled]="!share.active || !share.required || !share.enforceOrder || !userCanUpdate"
                            ></app-toggle>
                        </div>
                        <div class="item microburst" *ngIf="this.sendReminders">
                            <div class="microburst-settings" [ngClass]="{'disabled': !packet.duration || !share.active || !userCanUpdate}">
                                Remind to do <input
                                    type="number"
                                    name="consumeMinutes"
                                    min="1"
                                    oninput="validity.valid || (value = ''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                                    (input)="this.setDefaultDueDays();"
                                    [(ngModel)]="share.consumeMinutes"
                                    [disabled]="!packet.duration || !userCanUpdate"
                                /> minutes
                                <br>
                                every
                                <input
                                    type="number"
                                    name="consumeInterval"
                                    min="1"
                                    oninput="validity.valid || (value = ''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                                    (input)="this.setDefaultDueDays();"
                                    [(ngModel)]="share.consumeInterval"
                                    [disabled]="!packet.duration || !userCanUpdate"
                                /> days
                                <br>
                                and finish within
                                <input
                                    type="number"
                                    name="dueDays"
                                    min="1"
                                    oninput="validity.valid || (value = ''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                                    [(ngModel)]="share.dueDays"
                                    [disabled]="!packet.duration"
                                /> days
                            </div>
                        </div>
                    </div>
                    <div class="privacy-setting">
                        <app-radiobox
                            name="privacy"
                            [(value)]="share.privacy"
                            default="public"
                            label="Anyone"
                            disabled="!userCanUpdate"
                        ></app-radiobox>
                        <app-radiobox
                            name="privacy"
                            [(value)]="share.privacy"
                            default="verified"
                            label="Verified"
                            disabled="!userCanUpdate"
                        ></app-radiobox>
                        <app-radiobox
                            name="privacy"
                            [(value)]="share.privacy"
                            default="invited"
                            label="Assigned"
                            disabled="!userCanUpdate"
                        ></app-radiobox>

                        <button
                            class="app-button small green"
                            *ngIf="enableSaveShares() && userCanUpdate"
                            (click)="saveShareSettings()"
                        >
                            Save
                        </button>
                    </div>
                </div>

                <!--Choice Details-->
                <div
                    class="choice-details"
                    *ngIf="
                        !isOverviewSelected &&
                        selectedQuestionId &&
                        selectedQuestion &&
                        selectedQuestion.type !== 'short_answer'
                    "
                >
                    <div class="header">
                        <span>Answer Details</span>
                        <button
                            class="app-button small blue"
                            *ngIf="selectedQuestion.imageUrl"
                            (click)="
                                viewQuestionImage(selectedQuestion.imageUrl)
                            "
                        >
                            <i class="material-icons icon">visibility</i> View
                            Image
                        </button>
                    </div>
                    <div class="details">
                        <div
                            [class]="
                                'item ' + (item.imageUrl ? 'has-image' : '')
                            "
                            *ngFor="let item of analytics.choices"
                        >
                            <div
                                [class]="
                                    'item-percentage ' +
                                    (selectedQuestion.scored
                                        ? 'scored'
                                        : 'unscored')
                                "
                                [ngStyle]="{
                                    backgroundColor: getScoreColor(item)
                                }"
                            >
                                {{ getPercentage(item.answerPercentage) }}%
                            </div>
                            <div class="item-text">
                                {{ item.text }}
                            </div>
                            <div class="item-action">
                                <button
                                    class="app-button x-small blue no-text"
                                    *ngIf="item.imageUrl"
                                    (click)="viewQuestionImage(item.imageUrl)"
                                >
                                    <i class="material-icons icon"
                                        >visibility</i
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Users Table-->
            <div class="selected-view-table">
                <app-x-table
                    [page]="'PACKET-ASSIGNMENTS'"
                    [id]="packet.id"
                    [title]="packet.title"
                    [microburst]="microburst"
                    [searchText]="searchText"
                    [xTableData]="xTableData"
                    [xTableFilters]="xTableFilters"
                    [canSelectRows]="true"
                    [showSentiment]="true"
                    [analyticsData]="analyticsFilterData"
                    [numberOfPages]="numberOfPages"
                    [numberOfResults]="numberOfResults"
                    [results]="results"
                    [numbersPerPage]="numbersPerPage"
                    [currentPageIndex]="currentPageIndex"
                    [entityName]="entityName"
                    [noDataText]="noDataText"
                    [infoLink]="infoLink"
                    [searchDelayMs]="getSearchDelayMs()"
                    [updatePaging]="updatePaging.asObservable()"
                    [selectedRows]="selectedContacts"
                    [excludedRows]="excludedContacts"
                    [selectedAll]="selectedAllContacts"
                    (handleSearchTextChange)="handleSearchTextChange($event)"
                    (handleFiltersChange)="handleFiltersChange($event)"
                    (handlePageChange)="handlePageChange($event)"
                    (handleSelectedRows)="handleSelectedRows($event)"
                    (handleExcludedRows)="handleExcludedRows($event)"
                    (handleUpdateUserTables)="updateViewerTableRows(true)"
                    (handleSelectedAllRows)="handleSelectedAllRows($event)"
                    (export)="export()"
                >
                </app-x-table>
            </div>
        </div>
    </div>
</div>

<!--No packet selected-->
<div class="no-ving-selected" *ngIf="!packet">
    <div class="no-ving-selected-text">Select packet at left.</div>
</div>
